<template>
   <div class="readCardOrderSetup">
        <div class="scroll-box">
            <div class="tr-li">
                <div class="lable-txt"></div>
                <div class="from-input">
                    <el-checkbox v-model="isEnableReadCard" label="启用读卡点菜功能"></el-checkbox>
                </div>
            </div>
            <div class="tr-li">
                <div class="lable-txt">硬件型号:</div>
                <div class="from-input">
                    <el-select v-model="brand" placeholder="请选择" >
                        <el-option label="1-[BESTECHTYPE]" :value="1" > </el-option>
                    </el-select>
                </div>
            </div>
            <div class="tr-li">
                <div class="lable-txt">端口:</div>
                <div class="from-input">
                    <el-select v-model="port" placeholder="请选择" >
                            <el-option  :label="'COM'+item" :value="item"  v-for="item in 8" :key="item"></el-option>
                        </el-select>
                </div>
            </div>
            <div class="tr-li">
                <div class="lable-txt">波特率:</div>
                <div class="from-input">
                    <el-select v-model="baud" placeholder="请选择" >
                        <el-option  :label="9600" :value="9600" ></el-option>
                        <el-option  :label="19200" :value="19200" ></el-option>
                        <el-option  :label="38400" :value="38400" ></el-option>
                        <el-option  :label="57600" :value="57600" ></el-option>
                        <el-option  :label="115200" :value="115200" ></el-option>
                    </el-select>           
                </div>
            </div>
            <p class="explain">注：注册文件开通【读卡点菜】功能后才可生效使用</p>
        </div>
        <div class="footer-box">
            <button class="btn" @click="back();">返回</button>
            <button class="btn selected" @click="saveConfig()">保存配置</button>
        </div>
   </div>
</template>

<script>
export default {
    name:'readCardOrderSetup',
    data(){
        return {
            /**硬件型号*/
            brand:1,//1 [BESTECHTYPE]
            port:1,
            baud:57600,
            isEnableReadCard:false,
        }
    },
    mounted(){
        
        this.$webBrowser.getReadCardOrder().then(d=>{//获取电子秤配置信息
            if(d){
                this.isEnableReadCard=d.isEnableReadCard==true;
                this.brand=d.brand||this.brand;
                this.port=d.port||1;
                this.baud=parseInt(d.baud)||57600;
            }
        });
    },
    methods:{
        /**退出 */
        back(){
            this.$emit("close");
        },
        /**保存配置 */
        saveConfig(){
            let data={
                brand:this.brand,
                port:this.port,
                baud:this.baud,
                isEnableReadCard:this.isEnableReadCard
            }
            this.$webBrowser.saveReadCardOrder(data).then(d=>{
                if(d && d.state==0){
                    this.$message.success("保存成功");
                }
            });
        }
    }
}
</script>

<style lang="scss">
@import './readCardOrderSetup.scss';
</style>